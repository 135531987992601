/**
 * Factory for creating timezoned-date formatters.
 *
 * The only reason to do it like this is performance: by having a static class we avoid creating
 * too many objects. A dataset can have tens of thousands dates and converting them all at once
 * is a major bottleneck.
 */
export class TimeZoneFormatterFactory {
  private static isoFormatters: Map<string, Intl.DateTimeFormat> = new Map();
  private static enFormatters: Map<string, Intl.DateTimeFormat> = new Map();

  /**
   * Returns an Intl.DateTimeFormat formatter that formats dates according to its type to string
   * in the given time zone.
   *
   * @param type "iso" formats to ISO 8601 string, "en" formats to en-US string
   * @param timeZone time zone (e.g. "Europe/Vienna" or "UTC")
   * @returns Intl.DateTimeFormat instance
   */
  public static instance(
    type: "iso" | "en",
    timeZone: string
  ): Intl.DateTimeFormat {
    switch (type) {
      case "iso":
        return this.isoInstance(timeZone);
      case "en":
        return this.enInstance(timeZone);
    }
  }

  /**
   * Returns an Intl.DateTimeFormat formatter that formats dates to ISO 8601 string
   * in the given time zone.
   *
   * @param timeZone time zone (e.g. "Europe/Vienna" or "UTC")
   * @returns Intl.DateTimeFormat instance
   */
  private static isoInstance(timeZone: string): Intl.DateTimeFormat {
    if (!this.isoFormatters.has(timeZone)) {
      this.isoFormatters.set(
        timeZone,
        // inspiration: https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
        new Intl.DateTimeFormat("sv", {
          timeZone,
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        })
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.isoFormatters.get(timeZone)!;
  }

  /**
   * Returns an Intl.DateTimeFormat formatter that formats dates to en-US string
   * in the given time zone.
   *
   * @param timeZone time zone (e.g. "Europe/Vienna" or "UTC")
   * @returns Intl.DateTimeFormat instance
   */
  private static enInstance(timeZone: string): Intl.DateTimeFormat {
    if (!this.enFormatters.has(timeZone)) {
      this.enFormatters.set(
        timeZone,
        new Intl.DateTimeFormat("en-US", {
          timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
          weekday: "long",
        })
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.enFormatters.get(timeZone)!;
  }
}
