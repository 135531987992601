import React from "react";

import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import classNames from "classnames";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { BUTTON_ICON_MEDIUM, BUTTON_ICON_SMALL } from "../buttons";

const SMALL =
  "text-xs py-1.5 pl-2 pr-8 focus:border-gray-300 focus:ring-offset-1 focus:ring-blue-300";
const MEDIUM = "text-sm py-2 pl-3 pr-10";

const DIRECTION_DOWN = "";
const DIRECTION_UP = "-top-2 transform -translate-y-full";

export type SelectOption = {
  [key: string]: string | number | boolean | null;
};

export type BaseSelectComponentProps<Option> = {
  id: string;
  buttonText: string;
  label?: string;
  keyProp: keyof Option & string;
  disabled?: boolean;
  options: readonly Option[];
  size?: "small" | "medium";
  error?: string | boolean;
  info?: string;
  autoFocus?: boolean;
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  getOptionLabel(...options: Option[]): string | null;
};

type SelectComponentProps<Option> = BaseSelectComponentProps<Option> &
  (
    | { multiple: true; values: Option[]; onChange(values: Option[]): void }
    | { multiple: false; value: Option | null; onChange(value: Option): void }
  );

export default function SelectComponent<Option extends SelectOption>(
  props: SelectComponentProps<Option>
): JSX.Element {
  const { id, buttonText, label, keyProp, disabled, options } = props;
  const { size = "medium" } = props;
  const { error, info, autoFocus, getOptionLabel } = props;
  const { className, labelClassName, containerClassName } = props;

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const direction = useDirection(buttonRef, options.length, size);

  return (
    <Listbox
      by={keyProp}
      value={props.multiple ? props.values : props.value}
      onChange={props.onChange}
      disabled={disabled}
      multiple={props.multiple}
    >
      {label && (
        <Label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </Label>
      )}
      <div id={id} className={classNames("relative", containerClassName)}>
        <ListboxButton
          ref={buttonRef}
          // probably a library bug: the custom ids don't work
          //id={`${id}-button`}
          className={classNames(
            "relative w-full cursor-pointer rounded border border-gray-300 bg-white disabled:bg-gray-100 transition-colors duration-300 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed",
            size === "small" ? SMALL : MEDIUM,
            error
              ? "border-red-400 focus:border-red-500 focus:ring-red-500"
              : "",
            className
          )}
          autoFocus={autoFocus}
        >
          <span className={classNames("block truncate", labelClassName)}>
            {buttonText}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className={classNames(
                "text-gray-400",
                size === "small" ? BUTTON_ICON_SMALL : BUTTON_ICON_MEDIUM
              )}
              aria-hidden="true"
            />
          </span>
        </ListboxButton>
        <ListboxOptions
          className={classNames(
            "absolute z-10 mt-1 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg",
            "ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
            direction === "up" ? DIRECTION_UP : DIRECTION_DOWN,
            // when this is changed, also adapt the constant MAX_HEIGHT:
            "max-h-60"
          )}
        >
          {options.map((option, index) => (
            <ListboxOption
              key={option[keyProp]?.toString() ?? `none-${index}`}
              // probably a library bug: the custom ids don't work
              //id={`${id}-option-${option[keyProp]?.toString() ?? `none-${index}`}`}
              className={({ focus, selected }) =>
                classNames(
                  focus ? "text-white bg-blue-600" : "text-gray-900",
                  "relative cursor-pointer select-none",
                  size === "small" ? SMALL : MEDIUM,
                  selected ? "" : size === "small" ? "pr-2" : "pr-3"
                )
              }
              value={option}
              onDoubleClick={
                props.multiple
                  ? () =>
                      props.onChange(
                        props.values.length < options.length ? [...options] : []
                      )
                  : undefined
              }
            >
              {({ focus, selected }) => (
                <>
                  <span
                    id={`${id ?? "select"}-option-${option[keyProp]}`}
                    className={classNames(
                      selected ? "font-semibold" : "font-normal",
                      "block truncate"
                    )}
                  >
                    {getOptionLabel(option)}
                  </span>
                  {selected ? (
                    <span
                      className={classNames(
                        focus ? "text-white" : "text-blue-600",
                        "absolute inset-y-0 right-0 flex items-center pr-4"
                      )}
                    >
                      <CheckIcon
                        className={
                          size === "small"
                            ? BUTTON_ICON_SMALL
                            : BUTTON_ICON_MEDIUM
                        }
                        aria-hidden="true"
                      />
                    </span>
                  ) : null}
                </>
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
      {typeof error === "string" && !!error && (
        <p className="mt-2 text-sm text-red-600">{error}</p>
      )}
      {info && !error && <p className="mt-2 text-sm text-gray-500">{info}</p>}
    </Listbox>
  );
}

function useDirection(
  buttonRef: React.RefObject<HTMLButtonElement>,
  optionsCount: number,
  size: "small" | "medium"
): "up" | "down" {
  const [direction, setDirection] = React.useState<"up" | "down">("down");

  React.useEffect(() => {
    const updateDirection = () => {
      if (!buttonRef.current) {
        return;
      }
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const spaceAbove = buttonRect.top;
      const spaceBelow = window.innerHeight - buttonRect.bottom;

      const dropdownHeight = getDropdownHeight(optionsCount, size);
      const margin = 8;

      if (spaceBelow - margin < dropdownHeight && spaceAbove > dropdownHeight) {
        setDirection("up");
      } else {
        setDirection("down");
      }
    };

    updateDirection();

    window.addEventListener("resize", updateDirection);
    window.addEventListener("scroll", updateDirection);

    const observer = new MutationObserver(() => {
      updateDirection();
    });
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      window.removeEventListener("resize", updateDirection);
      window.removeEventListener("scroll", updateDirection);

      observer.disconnect();
    };
  }, [buttonRef.current]);

  return direction;
}

const MAX_HEIGHT = 244;
const SMALL_HEIGHT = 28;
const MEDIUM_HEIGHT = 36;
const PADDINGS_MARGINS = 12; // 2 * 4 + 4
function getDropdownHeight(
  optionsCount: number,
  size: "medium" | "small"
): number {
  switch (size) {
    case "small":
      return Math.min(
        MAX_HEIGHT,
        optionsCount * SMALL_HEIGHT + PADDINGS_MARGINS
      );
    case "medium":
      return Math.min(
        MAX_HEIGHT,
        optionsCount * MEDIUM_HEIGHT + PADDINGS_MARGINS
      );
  }
}
