import { useQuery } from "@tanstack/react-query";

import { settingsQuery, useEditSettings } from "../../../api/user";
import { UserSettings, defaultUserSettings } from "../models/settings";

export default function useSettings() {
  const { data: settings } = useQuery(settingsQuery());

  return settings ?? defaultUserSettings;
}

export function useSettingsState(): [
  UserSettings,
  (patch: Partial<UserSettings>) => Promise<UserSettings>,
] {
  const settings = useSettings();
  const editSettings = useEditSettings();

  const callback = (patch: Partial<UserSettings>) => {
    return editSettings.mutateAsync(patch);
  };

  return [settings, callback];
}
