import { z } from "zod";

// has to be ordered to make the function compareScales
// working (defined below)
// prettier-multiline-arrays-next-line-pattern: 6
export const aggregateOptions = [
  "10m",
  "15m",
  "30m",
  "1h",
  "1D",
  "1W",
  "1M",
  "1Q",
  "1Y",
] as const;
export const AggregateZod = z.enum(aggregateOptions);
export type Aggregate = z.infer<typeof AggregateZod>;

export const DavidsAggregateZod = z.preprocess((arg) => {
  if (typeof arg !== "string") {
    return arg;
  }

  if (!arg.match(/^\d/)) {
    return `1${arg.replace("H", "h")}`;
  }

  return arg.replace("min", "m");
}, AggregateZod);

export function compareScales(
  a: Aggregate,
  op: "<" | "<=" | ">=" | ">",
  b: Aggregate
): boolean {
  const aIndex = aggregateOptions.indexOf(a);
  const bIndex = aggregateOptions.indexOf(b);

  switch (op) {
    case "<":
      return aIndex < bIndex;
    case "<=":
      return aIndex <= bIndex;
    case ">=":
      return aIndex >= bIndex;
    case ">":
      return aIndex > bIndex;
  }
}
