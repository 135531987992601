import React from "react";
import { RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getAnalysisPath } from "./analyzer";

const Settings = lazyWithRetry(
  () => import("../pages/AnalysisSettings/AnalysisSettings")
);
const General = lazyWithRetry(
  () => import("../pages/AnalysisSettings/sections/General")
);
const DataSelection = lazyWithRetry(
  () => import("../pages/AnalysisSettings/sections/DataSelection")
);
const Colors = lazyWithRetry(
  () => import("../pages/AnalysisSettings/sections/Colors")
);
const HeatmapSettings = lazyWithRetry(
  () => import("../pages/AnalysisSettings/sections/HeatmapSettings")
);

const SETTINGS_PATH = "settings";

export const settingsPages = [
  "general",
  "dataSelection",
  "colors",
  "heatmap",
] as const;
export const SettingsPageZod = z.enum(settingsPages);
export type SettingsPage = z.infer<typeof SettingsPageZod>;

export const settingsPath: RouteObject = {
  path: SETTINGS_PATH,
  element: <Settings />,
  children: [
    { index: true, element: <General /> },
    { path: SettingsPageZod.Enum.general, element: <General /> },
    { path: SettingsPageZod.Enum.dataSelection, element: <DataSelection /> },
    { path: SettingsPageZod.Enum.colors, element: <Colors /> },
    { path: SettingsPageZod.Enum.heatmap, element: <HeatmapSettings /> },
  ],
};

export function getSettingsPath(
  solutionId: string | number,
  analysisId: string | number
): string {
  return `${getAnalysisPath(solutionId, analysisId)}/${SETTINGS_PATH}`;
}
