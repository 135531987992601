import React from "react";

import Apps from "../../components/Apps";
import HeroBreadcrumbs from "../../components/HeroBreadcrumbs";

export default function AppsOverview(): JSX.Element {
  return (
    <>
      <HeroBreadcrumbs path={[]} />
      <div className="p-4 w-full grow flex flex-col items-center justify-center">
        <Apps
          id="overviewApps"
          size="large"
          className="w-full flex flex-wrap justify-center gap-8"
          omitApps={["home"]}
        />
      </div>
    </>
  );
}
