import React from "react";

interface ButtonProps<T> {
  key: T;
  label?: string;
}

type ButtonSize = "small" | "medium";

export interface ButtonMultiGroupProps<T> {
  id?: string;
  label?: string;
  options: ButtonProps<T>[];
  active?: T[];
  maxRowItems?: number;
  disabled?: boolean;
  onClick(key: T): void;
  size?: ButtonSize;
  wrapperClassName?: string;
}

const COMMON_STYLE =
  "border border-gray-300 font-medium focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500";

const PASSIVE_STYLE =
  " bg-white text-gray-700 hover:bg-gray-50 disabled:bg-gray-100";
const ACTIVE_STYLE =
  " bg-blue-100 text-blue-700 hover:bg-blue-200 disabled:bg-gray-300 disabled:text-gray-700";

const SMALL = "px-2.5 py-1.5 text-xs";
const MEDIUM = "px-4 py-2 text-sm";

export default function ButtonMultiGroup<T extends string | number | boolean>(
  props: ButtonMultiGroupProps<T>
) {
  const { id, label, options, active = [], onClick } = props;
  const { maxRowItems = Number.MAX_SAFE_INTEGER, size = "medium" } = props;
  const { wrapperClassName = "", disabled = false } = props;

  if (options.length < 2) {
    throw new Error("There must be at least two options.");
  }

  if (maxRowItems < 1) {
    throw new Error("There must be at least one button per line");
  }

  const rows = splitArrayToChunks(options, maxRowItems);
  balanceLastRow(rows, Math.floor(maxRowItems / 2) + 1);

  const getButton = (button: ButtonProps<T>, row: number, col: number) => {
    const extraClass = getButtonExtraClasses(rows, row, col);

    const width = (100 / rows[row].length).toFixed(4) + "%";

    const key =
      typeof button.key === "boolean"
        ? button.key
          ? "True"
          : "False"
        : button.key.toString();

    return (
      <button
        key={key}
        id={id ? `${id}-${key}` : undefined}
        onClick={() => onClick(button.key)}
        type="button"
        className={`${COMMON_STYLE} ${
          size === "small" ? SMALL : MEDIUM
        } ${extraClass} ${
          active.includes(button.key) ? ACTIVE_STYLE : PASSIVE_STYLE
        }`}
        style={{ width: col === 0 ? width : `calc(${width} + 1px)` }}
        disabled={disabled}
      >
        {button.label ?? button.key}
      </button>
    );
  };

  return (
    <>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div id={id} className={`isolate rounded shadow-sm ${wrapperClassName}`}>
        {rows.map((row, rowIndex) => (
          <div
            key={row.map((b) => b.key).join("-")}
            className="inline-flex w-full"
          >
            {row.map((btn, colIndex) => getButton(btn, rowIndex, colIndex))}
          </div>
        ))}
      </div>
    </>
  );
}

function splitArrayToChunks<T>(array: T[], chunkSize: number): T[][] {
  const result = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, [] as T[][]);

  return result;
}

function balanceLastRow<T>(rows: T[][], minRowItems: number) {
  const height = rows.length;
  // single row can't be balanced
  if (height <= 1) {
    return;
  }
  // last row with at least _minRowItems_ doesn't have to be balanced
  if (rows[height - 1].length >= minRowItems) {
    return;
  }
  // there must be enough items for the last two rows, otherwise it doesn't make
  // sense to balance
  if (rows[height - 2].length + rows[height - 1].length < 2 * minRowItems) {
    return;
  }
  // balance by moving items from penultimate row to the last one
  while (rows[height - 1].length < minRowItems) {
    // eslint cannot recognize the invariant above
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rows[height - 1].unshift(rows[height - 2].pop()!);
  }
}

function getButtonExtraClasses<T>(
  rows: ButtonProps<T>[][],
  row: number,
  col: number
): string {
  let extraClass = "";

  if (row === 0 && col === 0) {
    extraClass += " rounded-tl";
  }
  if (row === 0 && col === rows[0].length - 1) {
    extraClass += " rounded-tr";
  }
  if (row === rows.length - 1 && col === 0) {
    extraClass += " rounded-bl";
  }
  if (row === rows.length - 1 && col === rows[rows.length - 1].length - 1) {
    extraClass += " rounded-br";
  }

  if (row !== 0) {
    extraClass += " -mt-px";
  }
  if (col !== 0) {
    extraClass += " -ml-px";
  }

  return extraClass;
}
