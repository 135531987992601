import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TFunction } from "i18next";
import { z } from "zod";

import api, { EndpointOptions, getQueryString } from "../../../api";
import { Aggregate } from "../../../models/aggregate";
import { DateZod } from "../../../models/primitives";
import { addNullPoints } from "../../PrognosAI/functions/aggregate";
import { getLeafPartitions } from "../../PrognosAI/functions/partition";
import { Partitioner } from "../../PrognosAI/models/partitioner";
import {
  PaginatedResponse,
  paginatedResponse,
} from "../../PrognosAI/models/response";
import { SeriesPoint, SeriesPointZod } from "../../PrognosAI/models/series";
import { SolutionDetail } from "../../PrognosAI/models/solution";
import { getAnalysisPath, getAnalyzerPath } from "../routes/analyzer";

export const convertValuesOptions = [
  "None",
  "NullsToZeros",
  "ZerosToNulls",
] as const;
const ConvertValuesOptionZod = z.enum(convertValuesOptions);
export type ConvertValuesOption = z.infer<typeof ConvertValuesOptionZod>;

const HeatmapColorStepZod = z.object({
  value: z.number().min(0).max(1),
  color: z.string().min(1),
});
export type HeatmapColorStep = z.infer<typeof HeatmapColorStepZod>;

export const AnalysisZod = z.object({
  analysisId: z.number(),
  name: z.string(),
  description: z.string(),
  fromDate: DateZod.nullable(),
  toDate: DateZod.nullable(),
  minValue: z.number().nullable(),
  maxValue: z.number().nullable(),
  convertValues: ConvertValuesOptionZod,
  measurementId: z.number(),
  diffPartitionerIds: z.number().array(),
  partitionIds: z.number().array(),
  showComparison: z.boolean(),
  showAxisLabels: z.boolean(),
  colorPalette: z.string().min(1).array().nullable(),
  heatmapColors: HeatmapColorStepZod.array().nullable(),
  yAxisPrecision: z.number().nullable().catch(null),
  hoverPrecision: z.number().nullable().catch(null),
  showHeatmapGradient: z.boolean().catch(false),
  showHeatmapYearLines: z.boolean().catch(false),
  showHeatmapPercentiles: z.boolean().catch(false),
});
export type Analysis = z.infer<typeof AnalysisZod>;

export const DEFAULT_ANALYSIS: Omit<
  Analysis,
  "measurementId" | "partitionIds"
> = {
  analysisId: 0,
  name: "",
  description: "",
  fromDate: null,
  toDate: null,
  minValue: null,
  maxValue: null,
  convertValues: "None",
  diffPartitionerIds: [],
  showAxisLabels: true,
  showComparison: false,
  colorPalette: null,
  heatmapColors: null,
  yAxisPrecision: null,
  hoverPrecision: null,
  showHeatmapGradient: false,
  showHeatmapYearLines: false,
  showHeatmapPercentiles: false,
};

export function getDefaultAnalysis(
  solution: SolutionDetail | undefined
): Analysis | null {
  if (!solution) {
    return null;
  }

  const leafPartitions = getLeafPartitions(
    solution.partitions,
    solution.partitioners
  );
  return {
    ...DEFAULT_ANALYSIS,
    measurementId: solution.measurements.at(0)?.measurementId ?? 0,
    partitionIds: leafPartitions.map((p) => p.partitionId),
  };
}

export const AnalysisUsedIdentifiersZod = AnalysisZod.pick({
  analysisId: true,
  name: true,
});
export type AnalysisUsedIdentifiers = z.infer<
  typeof AnalysisUsedIdentifiersZod
>;

export const ANALYSIS_API = "/Analyzer/Analyses";

async function getAnalyses(
  solutionId: string | number,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<Analysis[]>> {
  const query = getQueryString(options);
  return paginatedResponse(AnalysisZod.array()).parse(
    (await api.get(`/Analyzer/Solutions/${solutionId}/Analyses?${query}`)).data
  );
}

export const analysesQuery = (
  solutionId: string | number,
  options?: EndpointOptions
) => ({
  queryKey: ["analyses", solutionId, ...(options ? [options] : [])],
  queryFn: () => getAnalyses(solutionId, options),
});

async function getAnalysis(analysisId: string | number): Promise<Analysis> {
  return AnalysisZod.parse(
    (await api.get(`${ANALYSIS_API}/${analysisId}`)).data
  );
}

export const analysisQuery = (analysisId: string | number) => ({
  queryKey: ["analysis", analysisId.toString()],
  queryFn: () => getAnalysis(analysisId),
});

export function useAnalysis(): [
  UseQueryResult<Analysis | undefined, Error>,
  string,
  number,
] {
  const { analysisId } = useParams();
  const numericId = parseInt(analysisId ?? "");
  if (!analysisId || isNaN(numericId)) {
    throw new Error("URL param :analysisId not provided or is not an integer.");
  }

  const queryData = useQuery(analysisQuery(analysisId));

  return [queryData, analysisId, numericId];
}

async function createAnalysis(
  solutionId: string,
  analysis: Analysis
): Promise<Analysis> {
  return AnalysisZod.parse(
    (await api.post(`/Analyzer/Solutions/${solutionId}/Analyses`, analysis))
      .data
  );
}

export const useCreateAnalysis = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (analysis: Analysis) => createAnalysis(solutionId, analysis),
    onSuccess: (data) => {
      toast.success(t("Analysis created successfully."));
      queryClient.invalidateQueries(analysesQuery(solutionId));
      navigate(getAnalysisPath(solutionId, data.analysisId));
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

async function updateAnalysis(analysisId: string, patch: Partial<Analysis>) {
  return AnalysisZod.parse(
    (await api.patch(`${ANALYSIS_API}/${analysisId}`, patch)).data
  );
}

export const useEditAnalysis = (solutionId: string, analysisId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<Analysis>) => updateAnalysis(analysisId, patch),
    onSuccess: (newAnalysis, patch) => {
      queryClient.setQueryData(["analysis", analysisId], newAnalysis);
      queryClient.invalidateQueries(analysesQuery(solutionId));
      queryClient.invalidateQueries(adHocPartitionsQuery(analysisId));
      queryClient.invalidateQueries({
        queryKey: getAdHocPartitionDataQueryPrefix(analysisId),
      });
      queryClient.invalidateQueries(analysisMinMaxDatesQuery(analysisId));
      if (patch.measurementId) {
        queryClient.removeQueries(analysisMinMaxDatesQuery(analysisId));
      }
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function copyAnalysis(analysisId: string | number) {
  return AnalysisZod.parse(
    (await api.post(`${ANALYSIS_API}/${analysisId}/Copy`)).data
  );
}

export const useCopyAnalysis = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: copyAnalysis,
    onSuccess: ({ analysisId }) => {
      queryClient.invalidateQueries(analysesQuery(solutionId));
      navigate(getAnalysisPath(solutionId, analysisId));
    },
    onError: () => {
      toast.error(t("An error occurred while copying. Please try again."));
    },
  });
};

async function deleteAnalysis(analysisId: string | number) {
  return api.delete(`${ANALYSIS_API}/${analysisId}`);
}

export const useDeleteAnalysis = (solutionId: string, redirect = false) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: deleteAnalysis,
    onSuccess: (_, analysisId) => {
      toast.success(t("Analysis deleted successfully."));
      queryClient.invalidateQueries(analysesQuery(solutionId));
      queryClient.removeQueries(analysisQuery(analysisId));
      if (redirect) {
        navigate(getAnalyzerPath(solutionId));
      }
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function getAnalysisUsedIdentifiers(
  solutionId: string | number
): Promise<AnalysisUsedIdentifiers[]> {
  return AnalysisUsedIdentifiersZod.array().parse(
    (
      await api.get(
        `/Analyzer/Solutions/${solutionId}/Analyses/UsedIdentifiers`
      )
    ).data
  );
}

export const analysisUsedIdentifiersQuery = (solutionId: string) => ({
  queryKey: ["analyses", solutionId, "usedIdentifiers"],
  queryFn: () => getAnalysisUsedIdentifiers(solutionId),
});

export const AdHocPartitionZod = z.object({
  adHocPartitionId: z.number().or(z.string()),
  originalPartitionId: z.number().nullable(),
  names: z.record(z.string(), z.string()),
});
export type AdHocPartition = z.infer<typeof AdHocPartitionZod>;

export function getAdHocPartitionName(
  adHocPartition: AdHocPartition,
  [...partitioners]: Partitioner[],
  t: TFunction
): string {
  if (Object.values(adHocPartition.names).length === 0) {
    return t("All data");
  }

  if (partitioners.length === 0) {
    return Object.values(adHocPartition.names).join(", ");
  }

  partitioners.sort((a, b) => a.order - b.order);

  const names: (string | null)[] = partitioners.map(
    (p) => adHocPartition.names[p.partitionerId] ?? null
  );
  const filteredNames = names.filter(
    (name: string | null): name is string => name !== null
  );

  if (filteredNames.length === 0) {
    return t("Unknown");
  }

  return filteredNames
    .map((name) => (name !== "" ? name : t("Unknown")))
    .join(", ");
}

async function getAdHocPartitions(
  analysisId: string | number
): Promise<AdHocPartition[]> {
  return AdHocPartitionZod.array().parse(
    (await api.get(`/Analyzer/Analyses/${analysisId}/AdHocPartitions`)).data
  );
}

export const adHocPartitionsQuery = (analysisId: string | number) => ({
  queryKey: ["adHocPartitions", analysisId.toString()],
  queryFn: () => getAdHocPartitions(analysisId),
});

async function getAdHocPartitionData(
  analysisId: string | number,
  adHocPartitionId: string | number,
  scale: Aggregate
): Promise<SeriesPoint[]> {
  try {
    const response = await api.get(
      `/Analyzer/Analyses/${analysisId}/SeriesData?adHocPartitionId=${adHocPartitionId}&dataInterval=${scale}`
    );
    const data = SeriesPointZod.array().parse(response.data);

    return addNullPoints(data, "x", "y", scale);
  } catch (e) {
    if (e instanceof AxiosError) {
      if (
        e.status === 422 &&
        e.response?.data.reason === "No data for given time range"
      ) {
        return [];
      }
    }
    throw e;
  }
}

function getAdHocPartitionDataQueryPrefix(analysisId: string | number) {
  return ["adHocPartitionsData", analysisId.toString()];
}

export const adHocPartitionDataQuery = (
  analysisId: string | number,
  adHocPartitionId: string | number | null,
  scale: Aggregate
) => ({
  queryKey: [
    ...getAdHocPartitionDataQueryPrefix(analysisId),
    adHocPartitionId,
    scale,
  ],
  queryFn: () =>
    adHocPartitionId
      ? getAdHocPartitionData(analysisId, adHocPartitionId, scale)
      : [],
  enabled: !!adHocPartitionId,
  staleTime: Infinity,
});

const MinMaxDatesZod = z
  .object({
    minDay: DateZod.nullable(),
    maxDay: DateZod.nullable(),
  })
  .transform((arg) => ({ minDate: arg.minDay, maxDate: arg.maxDay }));
export type MinMaxDates = z.infer<typeof MinMaxDatesZod>;

async function getAnalysisMinMaxDates(
  analysisId: string | number
): Promise<MinMaxDates> {
  return MinMaxDatesZod.parse(
    (await api.get(`/Analyzer/Analyses/${analysisId}/MinMaxDays`)).data
  );
}

export const analysisMinMaxDatesQuery = (analysisId: string | number) => ({
  queryKey: ["analysisMinMaxDates", analysisId.toString()],
  queryFn: () => getAnalysisMinMaxDates(analysisId),
  staleTime: Infinity,
});
