import { z } from "zod";

import { supportedLanguages } from "../../../services/i18n";
import { ImportExportFileTypeZod } from "./file";
import { SeriesTypeZod } from "./series";

export type PrognosLanguage = (typeof supportedLanguages)[number]["code"];
export const supportedLanguageCodes: [PrognosLanguage, ...PrognosLanguage[]] = [
  supportedLanguages[0].code,
  ...supportedLanguages.slice(1).map((p) => p.code),
];
const PrognosLanguageZod = z.enum(supportedLanguageCodes);

export const dateFormats = ["%d.%m.%Y", "%d/%m/%Y", "%m/%d/%Y"] as const;
export const DateFormatZod = z.enum(dateFormats);
export type DateFormat = z.infer<typeof DateFormatZod>;

export const datasetDateFormats = [...dateFormats, "%Y-%m-%d"] as const;
const DatasetDateFormatZod = z.enum(datasetDateFormats);
export type DatasetDateFormat = z.infer<typeof DatasetDateFormatZod>;

export const timeFormats = ["%H:%M", "%I:%M %p"] as const;
export const TimeFormatZod = z.enum(timeFormats);
export type TimeFormat = z.infer<typeof TimeFormatZod>;

export const datasetTimeFormats = [
  ...timeFormats,
  "%H:%M:%S",
  "%I:%M:%S %p",
  "",
] as const;
const DatasetTimeFormatZod = z.enum(datasetTimeFormats);
export type DatasetTimeFormat = z.infer<typeof DatasetTimeFormatZod>;

export const decimalSeparators = ["Point", "Comma"] as const;
export const DecimalSeparatorZod = z.enum(decimalSeparators);
export type DecimalSeparator = z.infer<typeof DecimalSeparatorZod>;

export const thousandsSeparators = ["Point", "Comma", "Space"] as const;
export const ThousandsSeparatorZod = z.enum(thousandsSeparators);
export type ThousandsSeparator = z.infer<typeof ThousandsSeparatorZod>;

export const exportColumnSeparators = [";", ",", "\t"] as const;
export const ExportColumnSeparatorZod = z.enum(exportColumnSeparators);
export type ExportColumnSeparator = z.infer<typeof ExportColumnSeparatorZod>;

export const resultMetrics = [
  "RSquared",
  "MAE",
  "MAPE",
  "SMAPE",
  "MASE",
  "RMSE",
] as const;
export const ResultMetricZod = z.enum(resultMetrics);
export type ResultMetric = z.infer<typeof ResultMetricZod>;

export const profileYScales = ["Mean", "Percentage"] as const;
export const ProfileYScaleZod = z.enum(profileYScales);
export type ProfileYScale = z.infer<typeof ProfileYScaleZod>;

export const UserSettingsZod = z.object({
  language: PrognosLanguageZod,
  dateFormat: DateFormatZod,
  timeFormat: TimeFormatZod,
  decimalSeparator: DecimalSeparatorZod,
  thousandsSeparator: ThousandsSeparatorZod,
  exportFileType: ImportExportFileTypeZod,
  exportColumnSeparator: ExportColumnSeparatorZod,
  defaultChartSeriesType: SeriesTypeZod,
  isDeveloper: z.boolean(),
  featureFlags: z.record(z.string(), z.unknown()),
  // result settings
  resultMetric: ResultMetricZod,
  resultIntradayProfilesYScale: ProfileYScaleZod,
  resultShowChangePoints: z.boolean(),
  resultShowFitDates: z.boolean(),
  resultShowCalcDates: z.boolean(),
  resultShowLegend: z.boolean(),
  resultHistoryDataShowSeries: z.boolean(),
  resultHistoryDataShowColumn: z.boolean(),
  resultHistoryDataPrecision: z.number().nullable().optional(),
  resultGroundTruthDataShowSeries: z.boolean(),
  resultGroundTruthDataShowColumn: z.boolean(),
  resultGroundTruthDataPrecision: z.number().nullable().optional(),
  resultForecastDataShowSeries: z.boolean(),
  resultForecastDataShowColumn: z.boolean(),
  resultForecastDataPrecision: z.number().nullable().optional(),
  resultInterpolatedDataShowSeries: z.boolean(),
  resultInterpolatedDataShowColumn: z.boolean(),
  resultInterpolatedDataPrecision: z.number().nullable().optional(),
  resultIgnoredDataShowSeries: z.boolean(),
  resultIgnoredDataShowColumn: z.boolean(),
  resultIgnoredDataPrecision: z.number().nullable().optional(),
});
export type UserSettings = z.infer<typeof UserSettingsZod>;

export const defaultUserSettings: UserSettings = {
  language: supportedLanguageCodes[0],
  dateFormat: dateFormats[0],
  timeFormat: timeFormats[0],
  decimalSeparator: decimalSeparators[0],
  thousandsSeparator: thousandsSeparators[1],
  exportFileType: "xlsx",
  exportColumnSeparator: ";",
  defaultChartSeriesType: "Step",
  isDeveloper: false,
  featureFlags: {},
  // result settings
  resultMetric: "RSquared",
  resultIntradayProfilesYScale: "Mean",
  resultShowChangePoints: true,
  resultShowFitDates: true,
  resultShowCalcDates: true,
  resultShowLegend: true,
  resultHistoryDataShowSeries: true,
  resultHistoryDataShowColumn: true,
  resultHistoryDataPrecision: null,
  resultGroundTruthDataShowSeries: true,
  resultGroundTruthDataShowColumn: true,
  resultGroundTruthDataPrecision: null,
  resultForecastDataShowSeries: true,
  resultForecastDataShowColumn: true,
  resultForecastDataPrecision: null,
  resultInterpolatedDataShowSeries: true,
  resultInterpolatedDataShowColumn: true,
  resultInterpolatedDataPrecision: null,
  resultIgnoredDataShowSeries: true,
  resultIgnoredDataShowColumn: true,
  resultIgnoredDataPrecision: null,
};
