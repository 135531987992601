import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import {
  ArchiveBoxIcon,
  ArrowLeftStartOnRectangleIcon,
  PlusIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { EndpointOptions } from "../../api";
import {
  solutionUsedIdentifiersQuery,
  solutionsQuery,
  useCreateSolution,
} from "../../api/solutions";
import { getUserName } from "../../apps/PrognosAI/components/formatter/Audit";
import useEndpoint from "../../apps/PrognosAI/hooks/useEndpoint";
import useSearchBoolean from "../../apps/PrognosAI/hooks/useSearchBoolean";
import {
  SolutionDraft,
  SolutionPhaseZod,
  solutionPhases,
} from "../../apps/PrognosAI/models/solution";
import PhaseIcon from "../../apps/PrognosAI/pages/Dashboard/components/PhaseIcon";
import Error from "../../apps/PrognosAI/pages/ErrorPage/ErrorPage";
import { generateSolutionNameError } from "../../apps/PrognosAI/pages/SolutionSettings/SolutionSettings";
import { getTasksPath } from "../../apps/PrognosAI/routes/tasks";
import { getUserSettingsPath } from "../../apps/PrognosAI/routes/userSettings";
import { tenum } from "../../apps/PrognosAI/services/translationMappings";
import Backlink from "../../components/Backlink";
import Card from "../../components/Card";
import DataTable from "../../components/DataTable";
import Form from "../../components/Form";
import Hero from "../../components/Hero";
import HeroActionButton from "../../components/HeroActionButton";
import HeroSubtitle from "../../components/HeroSubtitle";
import Slideover from "../../components/Slideover";
import Button from "../../components/buttons/Button";
import TextField from "../../components/form/TextField";
import TimezonePicker from "../../components/form/TimezonePicker";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { getSolutionsPath } from "../../routes/solutions";
import { DEFAULT_PAGE_SIZE } from "../../services/constants";
import LogoutForm from "../Login/LogoutForm";

const DEFAULT_SOLUTION: SolutionDraft = {
  name: "",
  dataInterval: "Invalid",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  description: "",
  auditCreatedAt: new Date(),
  auditCreatedBy: null,
  auditChangedAt: null,
  auditChangedBy: null,
  phase: "New",
};

const DEFAULT_ENDPOINT_OPTIONS: EndpointOptions = {
  sort: [["name", "asc"]],
  pageSize: DEFAULT_PAGE_SIZE,
};

export default function Solutions(): JSX.Element {
  const [showArchived, setShowArchived] = useSearchBoolean("showArchived");
  const handleArchivedToggle = () => {
    setShowArchived(!showArchived);
  };

  const endpoint = useEndpoint(
    "Solutions",
    (opts) =>
      solutionsQuery({
        ...opts,
        filter: (opts.filter ?? [])
          .filter((f) => !showArchived || f[0] !== "phase")
          .concat([
            [
              "phase",
              showArchived ? "=" : "!=",
              SolutionPhaseZod.Enum.Archived,
            ],
          ]),
      }),
    DEFAULT_ENDPOINT_OPTIONS
  );
  const { queryResult: solutionsQ, options } = endpoint;
  const { onSortChange, onFilterChange } = endpoint;
  const { items: solutions = [], count } = solutionsQ.data ?? {};
  const { isLoading, isError, error, isPlaceholderData } = solutionsQ;

  const [newSolution, setNewSolution] =
    useState<SolutionDraft>(DEFAULT_SOLUTION);
  const [formOpen, setFormOpen] = useState(false);

  const createSolution = useCreateSolution();
  const handleSubmit = () => {
    createSolution.mutate(newSolution);
  };

  const { t } = useTranslation();
  useDocumentTitle(t("Projects"));

  const solutionNameRef = React.useRef<HTMLInputElement>(null);

  const { data: solutionUsedIdentifiers = [] } = useQuery(
    solutionUsedIdentifiersQuery()
  );

  if (isError) {
    return <Error error={error} />;
  }

  const nameError = generateSolutionNameError(
    null,
    newSolution.name,
    solutionUsedIdentifiers,
    t
  );

  return (
    <>
      <Hero
        actions={
          <>
            <LogoutForm id="projectsSignOut">
              {(btnProps) => (
                <HeroActionButton {...btnProps} title={t("Sign out")}>
                  <ArrowLeftStartOnRectangleIcon />
                </HeroActionButton>
              )}
            </LogoutForm>
            <Link to={getUserSettingsPath(undefined, undefined)}>
              <HeroActionButton
                id="userSettingsButton"
                title={t("User settings")}
              >
                <UserIcon />
              </HeroActionButton>
            </Link>
            <Link to={getTasksPath(undefined, undefined)}>
              <HeroActionButton
                id="systemTasksButton"
                title={t("System tasks")}
              >
                <RectangleStackIcon />
              </HeroActionButton>
            </Link>
            {!showArchived && (
              <HeroActionButton
                id="toggleArchived"
                onClick={handleArchivedToggle}
                title={t("Show archived projects")}
              >
                <ArchiveBoxIcon />
              </HeroActionButton>
            )}
            <HeroActionButton
              id="newSolutionButton"
              onClick={() => setFormOpen(true)}
              title={t("New project")}
              variant="primary"
            >
              <PlusIcon />
            </HeroActionButton>
          </>
        }
      >
        {showArchived ? t("Archived projects") : t("Projects")}
        {!!showArchived && (
          <HeroSubtitle>
            <Backlink id="backToActiveProjects" to={getSolutionsPath()}>
              {t("Back to active projects")}
            </Backlink>
          </HeroSubtitle>
        )}
      </Hero>
      <Card>
        <DataTable
          id="solutionsTable"
          columns={[
            {
              key: "phase",
              type: "enum",
              label: t("Stage"),
              sortable: true,
              filterable: true,
              content: "icon",
              options: {
                enumOptions: solutionPhases
                  .filter((key) => showArchived || key !== "Archived")
                  .map((key) => ({
                    key,
                    label: tenum(`SolutionPhase:${key}`, t),
                  })),
              },
            },
            {
              key: "name",
              label: t("Name"),
              type: "string",
              highlighted: true,
              filterable: true,
              sortable: true,
              shrink: "truncate",
            },
            {
              key: "auditCreatedAt",
              label: t("Created at"),
              type: "datetime",
              filterable: true,
              sortable: true,
            },
            {
              key: "auditCreatedBy",
              apiKey: "auditCreatedBy.username",
              label: t("Created by"),
              type: "string",
              filterable: true,
              sortable: true,
            },
            {
              key: "auditChangedAt",
              label: t("Changed at"),
              type: "datetime",
              filterable: true,
              sortable: true,
            },
            {
              key: "auditChangedBy",
              apiKey: "auditChangedBy.username",
              label: t("Changed by"),
              type: "string",
              filterable: true,
              sortable: true,
            },
            {
              key: "lastImportDate",
              label: t("Last history import"),
              type: "datetime",
              filterable: true,
              sortable: true,
            },
            {
              key: "lastResultDate",
              label: t("Last Prognos AI result"),
              type: "datetime",
              filterable: true,
              sortable: true,
            },
          ]}
          rows={solutions.map((s) => ({
            ...s,
            phase: <PhaseIcon phase={s.phase} />,
            auditCreatedBy: getUserName(s.auditCreatedBy),
            auditChangedBy: getUserName(s.auditChangedBy),
            dataInterval:
              s.dataInterval && s.dataInterval !== "Invalid"
                ? s.dataInterval
                : "",
          }))}
          linkKey="solutionId"
          rowKey="solutionId"
          showSkeleton={isLoading || isPlaceholderData}
          page={options.page}
          pageSize={options.pageSize}
          totalCount={count}
          sort={options.sort}
          filter={options.filter}
          onFilter={onFilterChange}
          onSort={onSortChange}
        />
      </Card>
      <Slideover
        title={t("New project")}
        open={formOpen}
        initialFocus={solutionNameRef}
        onClose={() => setFormOpen(false)}
      >
        <Form onSubmit={handleSubmit}>
          <TextField
            id="solution_name"
            ref={solutionNameRef}
            label={t("Name")}
            value={newSolution.name}
            onChange={(e) =>
              setNewSolution((prev) => ({ ...prev, name: e.target.value }))
            }
            error={nameError}
            supressErrorsBeforeEdit
          />
          <div>
            <TimezonePicker
              id="timezone"
              label={t("Time zone")}
              value={newSolution.timezone}
              onChange={(timezone) =>
                setNewSolution((prev) => ({
                  ...prev,
                  timezone,
                }))
              }
            />
          </div>
          <div className="mt-2 space-x-1 text-right">
            <Button
              id="saveNewSolutionButton"
              disabled={!!nameError}
              type="submit"
            >
              {t("Create project")}
            </Button>
          </div>
        </Form>
      </Slideover>
    </>
  );
}
