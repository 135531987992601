import React from "react";

import classNames from "classnames";

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import HelpTooltip from "../HelpTooltip";

export interface TextFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id: string;
  label?: string;
  error?: string | boolean;
  info?: string;
  supressErrorsBeforeEdit?: boolean;
  help?: string;
  action?: React.ReactNode;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref): JSX.Element => {
    const {
      id,
      type = "text",
      label,
      error,
      info,
      help,
      supressErrorsBeforeEdit = false,
      action,
      className = "",
      onChange,
      ...rest
    } = props;

    const [isEdited, setIsEdited] = React.useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsEdited(true);
      if (onChange) {
        onChange(e);
      }
    };

    const showError = !!error && (!supressErrorsBeforeEdit || isEdited);
    const showErrorText = showError && typeof error !== "boolean";

    const hasIcon = showError || !!action;

    return (
      <div>
        {label && (
          <label
            htmlFor={id}
            className="text-sm font-medium text-gray-700 flex items-center space-x-1"
          >
            <span>{label}</span>
            {help && (
              <HelpTooltip
                id={`${id}-helpTooltip`}
                text={help}
                variant="light"
                place="right"
                className="!bg-gray-50"
              />
            )}
          </label>
        )}
        <div className={`relative rounded shadow-sm ${label ? "mt-1" : ""}`}>
          <input
            id={id}
            ref={ref}
            type={type}
            className={classNames(
              "form-input block w-full",
              "rounded border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm",
              "disabled:bg-gray-100",
              "transition-colors duration-300",
              showError
                ? "border-red-400 focus:border-red-500 focus:ring-red-500"
                : "",
              className,
              hasIcon ? "pr-10" : ""
            )}
            onChange={handleChange}
            {...rest}
          />
          {showError && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
          {!showError && action && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              {action}
            </div>
          )}
        </div>
        {showErrorText && <p className="mt-2 text-sm text-red-600">{error}</p>}
        {info && !showErrorText && (
          <p className="mt-2 text-sm text-gray-500">{info}</p>
        )}
      </div>
    );
  }
);

export default TextField;
