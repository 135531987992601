import React from "react";
import { useTranslation } from "react-i18next";

import { FormatterProps } from ".";
import useSettings from "../../hooks/useSettings";
import { Precision } from "../../models/result";
import { DecimalSeparator, ThousandsSeparator } from "../../models/settings";

interface NumberProps extends FormatterProps {
  precision?: Precision;
  zeroPrecision?: boolean;
  grayZeros?: boolean;
  unit?: string;
  tabular?: boolean;
}

export default function Number(props: NumberProps): JSX.Element {
  const { value, precision = "max", grayZeros = false, unit, tabular } = props;
  const { zeroPrecision = false } = props;

  const settings = useSettings();
  const { t } = useTranslation();

  const classes = [];
  if (!value && grayZeros) {
    classes.push("text-gray-500");
  }

  if (typeof value !== "number") {
    if (typeof value === "string" && value.endsWith("inf")) {
      return (
        <span
          className={classes.join(" ")}
          title={value === "-inf" ? t("Negative infinity") : t("Infinity")}
        >
          {value.replace("inf", "∞")} {unit ?? ""}
        </span>
      );
    }

    return (
      <span className={classes.join(" ")}>
        {value?.toString()} {unit ?? ""}
      </span>
    );
  }

  const rawString = value.toString();

  let finalPrecision = typeof precision === "number" ? precision : undefined;
  if (value === 0 && !zeroPrecision) {
    finalPrecision = 0;
  }

  if (tabular) {
    classes.push("tabular-nums");
  }

  if (
    value !== 0 &&
    finalPrecision &&
    Math.abs(value) < 0.1 ** finalPrecision
  ) {
    return (
      <span title={rawString} className={classes.join(" ")}>
        {value > 0 ? "<" : ">-"}
        {formatNumber(
          parseFloat(`0.${"0".repeat(finalPrecision - 1)}1`),
          settings.decimalSeparator,
          settings.thousandsSeparator,
          finalPrecision
        )}
        {unit ? <> {unit}</> : ""}
      </span>
    );
  }

  const [fValue, fUnit] = getFinalValueAndUnit(value, unit);

  return (
    <span title={rawString} className={classes.join(" ")}>
      {formatNumber(
        fValue,
        settings.decimalSeparator,
        settings.thousandsSeparator,
        finalPrecision
      )}
      {fUnit ? <> {fUnit}</> : ""}
    </span>
  );
}

const mapThousandsSepToChar = (thousands: ThousandsSeparator): string => {
  switch (thousands) {
    case "Comma":
      return ",";
    case "Point":
      return ".";
    case "Space":
      return " ";
  }
};

export const mapDecimalSepToChar = (decimal: DecimalSeparator): string => {
  switch (decimal) {
    case "Comma":
      return ",";
    case "Point":
      return ".";
  }
};

export const formatNumber = (
  value: number,
  decimal: DecimalSeparator,
  thousands: ThousandsSeparator,
  precision?: number
): string => {
  if (Object.is(value, -0)) {
    value = 0;
  }

  return value
    .toLocaleString("en-US", {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    })
    .replace(/\./, "<decimal>")
    .replace(/,/g, mapThousandsSepToChar(thousands))
    .replace(/<decimal>/, mapDecimalSepToChar(decimal));
};

const getFinalValueAndUnit = (
  value: number,
  unit?: string
): [number, string?] => {
  if (unit === "B") {
    return formatBytes(value, 1);
  }
  if (unit === "KB") {
    return formatBytes(value * 1024, 1);
  }
  return [value, unit];
};

// based on https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
// prettier-multiline-arrays-next-line-pattern: 9
const BYTE_SIZES = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

const formatBytes = (bytes: number, decimals = 2): [number, string] => {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return [parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), BYTE_SIZES[i]];
};
