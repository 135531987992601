import React from "react";

import ConfirmationDialog from "../../apps/PrognosAI/components/dialogs/ConfirmationDialog";
import Button, { ButtonProps } from "./Button";

interface ConfirmationButtonProps extends ButtonProps {
  question: string;
}

export default function ConfirmationButton(
  props: ConfirmationButtonProps
): JSX.Element {
  const { question, onClick, children, ...rest } = props;

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleButtonClick = () => setOpenDialog(true);

  const handleClose = () => setOpenDialog(false);

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
    handleClose();
  };

  return (
    <>
      <Button onClick={handleButtonClick} {...rest}>
        {children}
      </Button>
      <ConfirmationDialog
        id={rest.id ? `${rest.id}-confirmDialog` : "confirmDialog"}
        title={children}
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
      >
        {question}
      </ConfirmationDialog>
    </>
  );
}
