import React from "react";

import env from "../services/env";
import { useAuth } from "./AuthProvider";

const WebSocketContext = React.createContext<WebSocket | null>(null);

const DEFAULT_RECONNECT_DELAY = 500;
const MAX_RECONNECT_DELAY = DEFAULT_RECONNECT_DELAY * 2 ** 6; // 32s

if (!env.WS_URL) {
  throw new Error("WS_URL parameter not specified in .env!");
}

const endpoint = env.WS_URL.match(/wss?:\/\//)
  ? env.WS_URL
  : `wss://${window.location.host}${env.WS_URL}`;

interface WebSocketProviderProps {
  children: React.ReactNode;
}

export default function WebSocketProvider(
  props: WebSocketProviderProps
): JSX.Element {
  const { children } = props;

  const connection = useAutoReconnectedSocket();

  usePeriodicPing(connection);

  return (
    <WebSocketContext.Provider value={connection}>
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket(): WebSocket | null {
  return React.useContext(WebSocketContext);
}

function useAutoReconnectedSocket(): WebSocket | null {
  const { isAuthenticated } = useAuth();

  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const delayRef = React.useRef(DEFAULT_RECONNECT_DELAY);

  const [connection, setConnection] = React.useState<WebSocket | null>(() =>
    isAuthenticated ? getWebSocketConnection(delayRef) : null
  );
  React.useEffect(() => {
    // on log in
    if (isAuthenticated) {
      setConnection((prev) => prev ?? getWebSocketConnection(delayRef));
    }
    // on log out / session expiration
    if (!isAuthenticated && connection) {
      connection.close();
      setConnection(null);
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (!connection) {
      return;
    }

    const onClose = () => {
      if (!isAuthenticated) {
        console.log("WebSocket closed due to signed-in session termination.");
        return;
      }
      console.warn(
        `WebSocket closed. Reconnecting in ${delayRef.current / 1000} seconds.`
      );
      timeoutRef.current = setTimeout(() => {
        setConnection(getWebSocketConnection(delayRef));
      }, delayRef.current);
      delayRef.current =
        delayRef.current < MAX_RECONNECT_DELAY
          ? delayRef.current * 2
          : delayRef.current;
    };

    connection.addEventListener("close", onClose);

    return () => {
      connection.removeEventListener("close", onClose);
    };
  }, [connection, setConnection, isAuthenticated]);

  React.useEffect(() => {
    return () => {
      connection?.close();
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return connection;
}

function getWebSocketConnection(
  delayRef: React.MutableRefObject<number>
): WebSocket {
  const connection: WebSocket = new WebSocket(endpoint);
  connection.onopen = function () {
    console.log(`WebSocket opened successfully on ${endpoint}.`);
    delayRef.current = DEFAULT_RECONNECT_DELAY;
  };
  connection.onerror = function (error) {
    console.error("WebSocket failed unexpectedly. Closing the socket.", error);
    connection.close();
  };

  return connection;
}

const PING_INTERVAL = 30_000;
const PING_PAYLOAD = JSON.stringify({ msg: "heartbeat" });

function usePeriodicPing(connection: WebSocket | null) {
  React.useEffect(() => {
    if (!connection) {
      return;
    }

    const interval = setInterval(
      () => connection.send(PING_PAYLOAD),
      PING_INTERVAL
    );

    return () => {
      clearInterval(interval);
    };
  }, [connection]);
}
