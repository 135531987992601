import React from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

import BasicSkeleton from "../../../../components/skeletons/BasicSkeleton";
import ChartSkeleton from "../../../../components/skeletons/ChartSkeleton";
import SettingsSkeleton from "../../../../components/skeletons/SettingsSkeleton";
import DataImportConfigMenu from "../../../DataStore/pages/DataImportConfig/components/DatasetMenu";
import ProjectDashboardSkeleton from "../../pages/Dashboard/components/ProjectDashboardSkeleton";
import FactorMenu from "../../pages/InfluencingFactor/components/FactorMenu";
import ModelMenu from "../../pages/Model/components/ModelMenu";
import OutlierMenu from "../../pages/Outlier/components/OutlierMenu";
import ResultDashboardSkeleton from "../../pages/Result/components/ResultDashboardSkeleton";
import RunMenu from "../../pages/Run/components/RunMenu";
import SettingsMenu from "../../pages/Settings/components/SettingsMenu";

interface BasicLayoutProps {
  children?: React.ReactNode;
}

export default function BasicLayout({
  children,
}: BasicLayoutProps): JSX.Element {
  const skeleton = useSkeleton();

  return (
    <main className="grow p-4 flex flex-col">
      <React.Suspense fallback={skeleton}>
        <Outlet />
        {children}
      </React.Suspense>
    </main>
  );
}

// TODO use the routes functions here instead of the regex matches
function useSkeleton(): JSX.Element {
  const location = useLocation();
  const { runResultId } = useParams();

  // project dashboard page
  if (location.pathname.match(/^\/auth\/\d+\/?$/)) {
    return <ProjectDashboardSkeleton />;
  }

  // project settings page
  if (location.pathname.match(/\/auth\/\d+\/settings/)) {
    return <SettingsSkeleton />;
  }

  // import config detail page
  if (location.pathname.match(/\/auth\/\d+\/import\/\d+/)) {
    return (
      <SettingsSkeleton menu={<DataImportConfigMenu dataset={undefined} />} />
    );
  }

  // chart detail page
  if (location.pathname.match(/\/auth\/\d+\/review\/charts\/\d+/)) {
    return <ChartSkeleton />;
  }

  // outlier detail page
  if (location.pathname.match(/\/auth\/\d+\/outliers\/\d+/)) {
    return <SettingsSkeleton menu={<OutlierMenu outlier={undefined} />} />;
  }

  // IF detail page
  if (location.pathname.match(/\/auth\/\d+\/influencingFactors\/list\/\d+/)) {
    return (
      <SettingsSkeleton menu={<FactorMenu influencingFactor={undefined} />} />
    );
  }

  // IF import detail page
  if (location.pathname.match(/\/auth\/\d+\/influencingFactors\/import\/\d+/)) {
    return <SettingsSkeleton />;
  }

  // run detail page
  if (location.pathname.match(/\/auth\/\d+\/runs\/\d+/)) {
    return <SettingsSkeleton menu={<RunMenu />} />;
  }

  // model detail page
  if (location.pathname.match(/\/auth\/\d+\/models\/\d+/)) {
    return <SettingsSkeleton menu={<ModelMenu model={undefined} />} />;
  }

  // result segment detail page
  if (location.pathname.match(/\/auth\/\d+\/results\/\d+\/\d+/)) {
    return <ChartSkeleton withSwitch />;
  }

  // result dashboard page
  if (location.pathname.match(/\/auth\/\d+\/results\/\d+/)) {
    return <ResultDashboardSkeleton runResultId={runResultId} />;
  }

  // user settings page
  if (location.pathname.includes("userSettings")) {
    return <SettingsSkeleton menu={<SettingsMenu />} />;
  }

  // task detail page
  if (location.pathname.match(/\/auth\/(\d+\/)?tasks\/\w/)) {
    return <SettingsSkeleton />;
  }

  return <BasicSkeleton />;
}
