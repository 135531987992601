import React from "react";

import { CustomIcon } from "../components/sidebar/Sidebar";

const AnalyzerIcon: CustomIcon = (props) => {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      version="1.1"
      id="svg4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
        id="path2"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 7.5739249,10.941622 9.8692543,13.236951 13.312249,8.0724583"
        id="path183"
        strokeWidth={1.5}
      />
    </svg>
  );
};

export default AnalyzerIcon;
