import React from "react";
import { RouteObject } from "react-router-dom";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { dailyProfilesRoute } from "./dailyProfiles";
import { heatmapPageRoute } from "./heatmap";
import { overviewRoute } from "./overview";
import { settingsPath } from "./settings";
import { timeSeriesRoute } from "./timeSeries";
import { weeklyProfilesRoute } from "./weeklyProfiles";
import { yearsComparisonRoute } from "./yearsComparison";

const HeatmapPage = lazyWithRetry(
  () => import("../pages/HeatmapPage/HeatmapPage")
);

export const analysisRoute: RouteObject = {
  path: ":analysisId",
  children: [
    { index: true, element: <HeatmapPage /> },
    settingsPath,
    heatmapPageRoute,
    overviewRoute,
    timeSeriesRoute,
    yearsComparisonRoute,
    weeklyProfilesRoute,
    dailyProfilesRoute,
  ],
};
